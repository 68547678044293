export default ({
  angel: 'Bênção do Anjo',
  assassin: 'Alvo do Assassino',
  bulletproof: 'Colete à prova de balas',
  bodyguard: 'Proteção do Guarda-costas',
  detective: 'Investigado',
  emergency: 'Resgatado',
  hunter: 'Alvo do caçador',
  kidnapper: 'Sequestrado',
  lawyer: 'Cliente',
  lynch: 'Votado',
  protector: 'Álibi verificado',
});
