export default ({
  angel: 'Anjo',
  assassin: 'Assassino',
  bodyguard: 'Guarda-costas',
  bulletproof: 'Vendedor de coletes',
  detective: 'Detetive',
  emergency: 'Socorrista',
  fool: 'Louco',
  gangster: 'Gangster',
  generic: 'Cidadão',
  hunter: 'Caçador',
  kidnapper: 'Sequestrador',
  lawyer: 'Advogado',
  mafioso: 'Mafioso',
  milician: 'Policial infiltrado',
  narrator: 'Narrador',
  protector: 'Testemunha do Álibi',
  suspect: 'Suspeito',
});
